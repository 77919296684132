import * as React from "react";

const NotFoundPage: React.FC = (): JSX.Element => {
    return (
        <main>
            <div>You shouldn't be here</div>
        </main>
    )
}

export default NotFoundPage